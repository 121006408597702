import request from './request.js';
import public_request from './public_request';
//获取角色列表
export function roles() {
    return request({
        url: '/personnel/roles',
        method: 'GET',
        params: public_request(),
    });
}
//获取角色
export function role({ roleId }) {
    return request({
        url: '/personnel/role',
        method: 'GET',
        params: public_request({
            roleId,
        }),
    });
}
//创建角色
export function add_role(data) {
    return request({
        url: '/personnel/role',
        method: 'post',
        data: public_request(data),
    });
}
//修改角色
export function modify_role(data) {
    return request({
        url: '/personnel/role',
        method: 'put',
        data: public_request(data),
    });
}
//删除角色
export function delete_role({ roleId }) {
    return request({
        url: '/personnel/role',
        method: 'delete',
        data: public_request({
            roleId,
        }),
    });
}
//设置角色权限
export function role_permission({ roleId, permissionIds }) {
    return request({
        url: '/personnel/role-permission',
        method: 'put',
        data: public_request({
            roleId,
            permissionIds,
        }),
    });
}
//设置角色授权范围
export function role_grant_scope({ roleId, type, departmentIds }) {
    return request({
        url: '/personnel/role-grant-scope',
        method: 'PUT',
        data: public_request({
            roleId,
            type,
            departmentIds,
        }),
    });
}
//获取权限列表
export function permissions({ roleId }) {
    return request({
        url: '/personnel/role-permissions-tree',
        method: 'get',
        params: public_request({ roleId }),
    });
}
//获取角色授权范围
export function grants({ roleId }) {
    return request({
        url: '/personnel/role-grant-scope',
        method: 'get',
        params: public_request({ roleId }),
    });
}

// 获取使用者列表
export function hasRolePeople(data) {
    return request({
        url: '/personnel/role-staffs',
        method: 'get',
        params: public_request({ roleId: data }),
    });
}
