<template>
    <div class="quanxian">
        <div class="k">
            <!-- <h1>角色设置</h1> -->
            <!-- 创建角色弹框 -->
            <span style="font-size: 16px;color: #333333;margin-right: 10px;font-weight: 600;">角色设置</span>
            <el-button slot="reference" type="primary" plain @click="showDialogAddEdit">创建角色</el-button>

            <el-table
                :data="tableData"
                border
                style=" width: 100%;margin-top:10px;overflow-y:scroll;height:85%"
            >
                <el-table-column type="index" label="序号" width="100" />
                <el-table-column prop="name" label="角色名称" />
                <el-table-column label="成员数量（点击查看）">
                    <template slot-scope="scope">
                        <div
                            style="color:#4086EC;cursor: pointer;"
                            @click="showCount(scope.row)"
                        >{{ scope.row.staffCount }}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="remarks" label="角色备注" />
                <el-table-column prop="createdTime" label="创建时间" />
                <el-table-column label="操作" width="200">
                    <template slot-scope="scope">
                        <!-- <el-link
              type="primary"
              style="margin-right: 15px"
              :underline="false"
              @click="handleEdit(scope.row)"
              >权限管理</el-link
                        >-->
                        <el-link
                            type="primary"
                            style="margin-right: 15px"
                            :underline="false"
                            @click="showDialogAddEdit(scope.row)"
                        >编辑</el-link>
                        <el-popover :ref="`popover-${scope.$index}`" placement="top" width="210">
                            <p style="margin-bottom: 30px">确定将该角色删除吗？</p>
                            <!-- <p style="margin-bottom: 7px">
                确定删除角色
                <span style="color: #fcb12b">{{ scope.row.name }}</span>
                            </p>-->
                            <div style="text-align: right; margin: 0">
                                <el-button
                                    size="mini"
                                    @click="
                    scope._self.$refs[`popover-${scope.$index}`].doClose()
                  "
                                >取消</el-button>
                                <span @click="handleDelete(scope.row)">
                                    <el-button
                                        type="primary"
                                        size="mini"
                                        @click="
                      scope._self.$refs[`popover-${scope.$index}`].doClose()
                    "
                                    >确定</el-button>
                                </span>
                            </div>
                            <el-link slot="reference" :underline="false" style="color:#DE3C3C">删除</el-link>
                        </el-popover>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 点击数量出现弹框的组件 -->
            <num-count ref="numCount" :visible.sync="showAddCount" :role-detail="roleDetail" />
            <!-- 点击新增出现的弹框 -->
            <add-or-edit ref="addOrEdit" :show-add-or-edit.sync="showAddOredit" />
        </div>
    </div>
</template>
<script>
import { roles, delete_role } from '../../api/character.js';
// import { c_values } from "../../api/public_content.js";
import { getYMDHMS } from '../../assets/js/time.js';
export default {
    components: {
        numCount: () => import('./roleComponents/num-count'),
        addOrEdit: () => import('./roleComponents/add-or-edit'),
    },
    data() {
        return {
            options: [],
            moren: 2,
            tableData: [],
            data2: [],
            data3: [],
            defaultProps: {
                children: 'children',
                label: 'resourceName',
            },
            // defaultProps1: {
            //   children: "children",
            //   label: "name",
            // },
            checked: true,
            dialogFormVisible: false,
            dialogFormVisible1: false,
            editForm: {
                name: '',
                remarks: '',
            },
            indexc: '',
            visible: false,
            visible1: false,
            activeName: 'first',
            selectedC: [],
            roldId: '',
            selected: [],
            buxiang: true,
            showAddCount: false,
            roleDetail: {},
            showAddOredit: false,
        };
    },
    mounted() {
        this.xr();
        // c_values({ dictionaryCode: "role-grant-scope-type" }).then((res) => {
        //   this.options = res.data.list;
        // });
        this.options = this.common.allValueNeed('role-grant-scope-type');
    },
    computed: {
        //监听选择指定部门的时候显示指定部门的菜单
        department() {
            for (let i = 0; i < this.options.length; i++) {
                if (this.options[i].number === this.moren) {
                    if (this.options[i].cnVal === '指定部门') {
                        return true;
                    }
                }
            }
            return false;
        },
    },
    methods: {
        //渲染角色菜单
        xr() {
            roles().then((res) => {
                if (res.code == 0) {
                    this.tableData = res.data.list;
                    for (let i = 0; i < this.tableData.length; i++) {
                        this.tableData[i].createdTime = getYMDHMS(
                            this.tableData[i].createdTime
                        );
                    }
                } else {
                    this.$message.error(res.error);
                }
            });
        },
        //确定删除按钮
        handleDelete(row) {
            delete_role({ roleId: row.id }).then((res) => {
                if (res.code == 0) {
                    this.xr();
                    this.$message({
                        message: '已删除角色',
                        type: 'success',
                    });
                    this.dialogFormVisible1 = false;
                } else {
                    // if(res.code == 13003){
                    //   this.$message
                    // }
                    this.$message.error(res.message);
                }
            });
        },
        cancel() {
            console.log('你点击了取消');
            this.visible = false;
            this.$refs.ruleForm.resetFields();
        },
        showCount(info) {
            this.showAddCount = true;
            console.log(info);
            this.$nextTick(() => {
                // this.roleDetail = info
                this.$refs.numCount.init(info);
            });
        },
        showDialogAddEdit(row) {
            console.log(row);
            this.showAddOredit = true;
            this.$refs.addOrEdit.init(row);
        },
    },
};
</script>
<style lang="less" scoped>
.main {
    ::v-deep .el-form-item__label {
        padding: 0;
    }
    .last {
        margin-bottom: 0;
    }
}
.quanxian {
    padding: 10px;
    background: #f6f6f6;
    height: calc(100vh - 60px);
    overflow: hidden;
    .k {
        width: 100%;
        height: 100%;
        background: white;
        border-radius: 10px;
        padding: 30px 20px;

        h1 {
            font-size: 24px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: rgba(34, 34, 34, 1);
            text-align: center;
            margin-bottom: 10px;
        }
    }
    p {
        margin-bottom: 10px;
    }
    ::v-deep.el-table::before {
        height: 0;
    }
    ::v-deep .el-table th {
        padding: 10px 0;
    }
    ::v-deep .el-table td {
        padding: 8px 0;
    }
    ::v-deep .el-table thead tr th {
        background: rgba(245, 247, 249, 1);
        color: #333;
        font-weight: 900;
    }
}
.qx h4 {
    background: #4285ec;
    color: white;
    padding: 10px;
    span {
        width: 33%;
        display: inline-block;
    }
}
.cc {
    width: 33%;
    display: inline-block;
}
.nei {
    padding: 5px 10px;
}
.qx > div {
    margin-bottom: 15px;
}
.qx {
    text-align: left;
    padding: 10px;
    border: 1px solid rgba(201, 201, 201, 1);
    border-radius: 4px;
    margin-bottom: 10px;
}
.qxsz {
    ::v-deep .el-dialog {
        width: 30%;
    }
}
::v-deep .el-dialog__header,
::v-deep .dialog-footer {
    text-align: center;
}
.mingc {
    text-align: left;
}
.mingc ::v-deep .el-input {
    width: 30%;
}
::v-deep .el-popover {
    text-align: left;
    span {
        font-size: 14px;
        font-weight: 400;
        color: rgba(85, 85, 85, 1);
    }
}
::v-deep .el-input {
    width: 200px;
}
.bj {
    ::v-deep .el-dialog {
        width: 20%;
    }
}
</style>
